import { ofType } from 'redux-observable';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, from, of, concat, defer, debounceTime } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { RETRIEVE_DOCUMENTO_LIST } from '../../data.const';
import {
  setListConfigAction,
  setLoaders,
  storeDocumentoListAction,
} from '../../data.action';
import { filler } from '../../../../../utils/utils';

const retrieveDocumentoListEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_DOCUMENTO_LIST),
    debounceTime(20),
    map(({ payload }: any) => payload),
    // tap(p => console.log(`[epic ${RETRIEVE_DOCUMENTO_LIST}]`, p)),
    withLatestFrom(state$),
    switchMap(([body, _state]) => {
      return concat(
        of(setLoaders({ documentoLoader: true })),
        of(setListConfigAction({ documento: body })),
        defer(() =>
          from(
            request({ path: `documento/list`, method: httpMethods.POST, body })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return { total: p?.total, result: [...p?.result, ...filler] };
            }),
            // tap(p => console.log(`[epic ${RETRIEVE_DOCUMENTO_LIST}]`, p)),
            // switchMap(() => EMPTY),
            switchMap((data) => of(storeDocumentoListAction(data))),
            catchError((err) => {
              console.error(`[epic ${RETRIEVE_DOCUMENTO_LIST}] error`, err);
              return EMPTY;
            })
          )
        ),
        of(setLoaders({ documentoLoader: false }))
      );
    })
  );

export default retrieveDocumentoListEpic;
