import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of, concat, defer, debounceTime } from 'rxjs';
import { httpMethods, request } from '../../../../../../services/http-service';
import { RETRIEVE_CHATS_TEAMS } from '../../../data.const';
import {
  setLoaders,
  setPopupInfo,
  storeTeamsChats,
} from '../../../data.action';

const retrieveChatsTeamsEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_CHATS_TEAMS),
    debounceTime(20),
    map(({ payload }: any) => payload),
    // tap(p => console.log(`[epic ${RETRIEVE_CHATS_TEAMS}]`, p)),
    withLatestFrom(state$),
    switchMap(([_state]) => {
      return concat(
        of(setLoaders({ chatsLoader: true })),
        defer(() =>
          from(
            request({
              path: `microsoft/chats`,
              method: httpMethods.GET,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message ?? p.errorBody);
              }
              return p?.result;
            }),
            tap((p) =>
              console.log(`[epic ${RETRIEVE_CHATS_TEAMS}] Retrieved chats:`, p)
            ),
            switchMap((data) => of(storeTeamsChats(data))),
            catchError((err) => {
              console.error(`[epic ${RETRIEVE_CHATS_TEAMS}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: err.message ?? 'Si é verificato un errore',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ chatsLoader: false }))
      );
    })
  );

export default retrieveChatsTeamsEpic;
