import {
  CREATE_PROJECT_DUE_DILIGENCE,
  DELETE_ANAGRAFICA,
  DELETE_ANAGRAFICA_CATEGORIA,
  DELETE_ANAGRAFICA_SPECIFICA,
  DELETE_CONFIGURATION,
  DELETE_DEPOSITO,
  DELETE_DIPARTIMENTO,
  DELETE_DOCUMENTO,
  DELETE_DOCUMENTO_TIPO,
  DELETE_DOCUMENTS_DUE_DILIGENCE,
  DELETE_EVENTO,
  DELETE_EVENTO_TIPO,
  DELETE_INCARICO,
  DELETE_NOTIFICA,
  DELETE_ONORARIO,
  DELETE_ONORARIO_INCARICO,
  DELETE_PRATICA,
  DELETE_PROJECT_DUE_DILIGENCE,
  DELETE_RATA,
  DELETE_RATA_INCARICO,
  DELETE_ROLE,
  DELETE_SPESA,
  DELETE_SPESA_CATEGORIA,
  DELETE_TIMESHEET,
  DELETE_TIMESHEET_TIPO,
  DELETE_USER,
  DOWNLOAD_DOCUMENTO,
  DOWNLOAD_DOCUMENTS_BY_TYPE,
  DOWNLOAD_DOCUMENTS_DUE_DILIGENCE,
  GET_DOCUMENTS_DUE_DILIGENCE,
  GET_DOCUMENTS_DUE_DILIGENCE_SUCCESS,
  MODIFY_DOCUMENTS,
  MODIFY_DOCUMENTS_DUE_DILIGENCE,
  RETRIEVE_ACCOUNT,
  RETRIEVE_ANAGRAFICA,
  RETRIEVE_ANAGRAFICA_CATEGORIA,
  RETRIEVE_ANAGRAFICA_LIST,
  RETRIEVE_ANAGRAFICA_SPECIFICA,
  RETRIEVE_CONFIGURATION,
  RETRIEVE_CONFIGURATION_LIST,
  RETRIEVE_DEPOSITO,
  RETRIEVE_DEPOSITO_LIST,
  RETRIEVE_DIPARTIMENTO_LIST,
  RETRIEVE_DOCUMENTO,
  RETRIEVE_DOCUMENTO_CATEGORIA_LIST,
  RETRIEVE_DOCUMENTO_LIST,
  RETRIEVE_DOCUMENTO_TIPO_LIST,
  RETRIEVE_EVENTO,
  RETRIEVE_EVENTO_LIST,
  RETRIEVE_EVENTO_TIPO_LIST,
  RETRIEVE_INCARICO,
  RETRIEVE_INCARICO_LIST,
  RETRIEVE_NATURA_GIURIDICA,
  RETRIEVE_NOTIFICA,
  RETRIEVE_NOTIFICA_LIST,
  RETRIEVE_ONORARIO,
  RETRIEVE_ONORARIO_INCARICO,
  RETRIEVE_ONORARIO_INCARICO_LIST,
  RETRIEVE_ONORARIO_LIST,
  RETRIEVE_PEMRMISSIONS,
  RETRIEVE_PRATICA,
  RETRIEVE_PRATICA_LIST,
  RETRIEVE_PRATICA_MATERIA,
  RETRIEVE_RATA,
  RETRIEVE_RATA_INCARICO,
  RETRIEVE_RATA_INCARICO_LIST,
  RETRIEVE_RATA_LIST,
  RETRIEVE_ROLE,
  RETRIEVE_ROLES,
  RETRIEVE_SPESA,
  RETRIEVE_SPESA_CATEGORIA_LIST,
  RETRIEVE_SPESA_LIST,
  RETRIEVE_TIMESHEET,
  RETRIEVE_TIMESHEET_LIST,
  RETRIEVE_TIMESHEET_TIPO_LIST,
  RETRIEVE_USER,
  RETRIEVE_USER_LIST,
  RETRIEVE_VISURE_PRICES,
  SET_CURRENT_ANAGRAFICA,
  SET_CURRENT_CONFIGURATION,
  SET_CURRENT_DEPOSITO,
  SET_CURRENT_DOCUMENTO,
  SET_CURRENT_EVENTO,
  SET_CURRENT_GRUPPO,
  SET_CURRENT_INCARICO,
  SET_CURRENT_NOTIFICA,
  SET_CURRENT_ONORARIO,
  SET_CURRENT_ONORARIO_INCARICO,
  SET_CURRENT_PRATICA,
  SET_CURRENT_RATA,
  SET_CURRENT_RATA_INCARICO,
  SET_CURRENT_ROLE,
  SET_CURRENT_SPESA,
  SET_CURRENT_TIMESHEET,
  SET_CURRENT_USER,
  SET_LIST_CONFIG,
  STORE_ACCOUNT,
  STORE_ANAGRAFICA_CATEGORIA,
  STORE_ANAGRAFICA_LIST,
  STORE_ANAGRAFICA_SPECIFICA,
  STORE_CONFIGURATION_LIST,
  STORE_DEPOSITO_LIST,
  STORE_DIPARTIMENTO_LIST,
  STORE_DOCUMENTO_CATEGORIA_LIST,
  STORE_DOCUMENTO_LIST,
  STORE_DOCUMENTO_TIPO_LIST,
  STORE_EVENTO_LIST,
  STORE_EVENTO_TIPO_LIST,
  STORE_INCARICO_LIST,
  STORE_NATURA_GIURIDICA,
  STORE_NOTIFICA_LIST,
  STORE_ONORARIO_INCARICO_LIST,
  STORE_ONORARIO_LIST,
  STORE_PERMISSIONS,
  STORE_PRATICA_LIST,
  STORE_PRATICA_MATERIA,
  STORE_RATA_INCARICO_LIST,
  STORE_RATA_LIST,
  STORE_ROLES,
  STORE_SPESA_CATEGORIA_LIST,
  STORE_SPESA_LIST,
  STORE_TIMESHEET_LIST,
  STORE_TIMESHEET_TIPO_LIST,
  STORE_UNREAD_NOTICES,
  STORE_USER_LIST,
  STORE_VISURE_PRICES,
  UPLOAD_DOCUMENTO,
  UPLOAD_DOCUMENTS_DUE_DILIGENCE,
  UPSERT_ACCOUNT,
  UPSERT_ANAGRAFICA,
  UPSERT_ANAGRAFICA_CATEGORIA,
  UPSERT_ANAGRAFICA_SPECIFICA,
  UPSERT_CONFIGURATION,
  UPSERT_DEPOSITO,
  UPSERT_DIPARTIMENTO,
  UPSERT_DOCUMENTO,
  UPSERT_DOCUMENTO_TIPO,
  UPSERT_DOCUMENTO_ONEDRIVE,
  UPSERT_EVENTO,
  UPSERT_EVENTO_TIPO,
  UPSERT_INCARICO,
  UPSERT_NOTIFICA,
  UPSERT_ONORARIO,
  UPSERT_ONORARIO_INCARICO,
  UPSERT_PRATICA,
  UPSERT_PRATICA_ONEDRIVE,
  UPSERT_RATA,
  UPSERT_RATA_INCARICO,
  UPSERT_ROLE,
  UPSERT_SPESA,
  UPSERT_SPESA_CATEGORIA,
  UPSERT_TIMESHEET,
  UPSERT_TIMESHEET_TIPO,
  UPSERT_USER,
  DELETE_DOCUMENTO_ONEDRIVE,
  STORE_MICROSOFT_ACCOUNT_STATUS,
  STORE_CAN_CONFIGURE_MICROSOFT,
  STORE_MICROSOFT_PROFILE_STATUS,
  SET_CURRENT_CLOUD_DOCUMENT,
  LOAD_FROM_CLOUD,
  SAVE_TO_CLOUD,
  EXPORT_PDF,
  ADD_BOOKMARK_TO_PDF,
  SEND_DOCUMENTO,
  RETRIEVE_CALENDARS_OUTLOOK,
  STORE_CALENDARS_OUTLOOK,
  STORE_CALENDAR_PERMISSIONS_OUTLOOK,
  RETRIEVE_CALENDAR_PERMISSIONS_OUTLOOK,
  CREATE_CALENDAR_PERMISSION_OUTLOOK,
  DELETE_CALENDAR_PERMISSION_OUTLOOK,
  CREATE_CALENDAR_OUTLOOK,
  DELETE_CALENDAR_OUTLOOK,
  STORE_CALENDAR_SCHEDULE_OUTLOOK,
  RETRIEVE_CALENDAR_SCHEDULE_OUTLOOK,
  UPSERT_DOCUMENTO_ONEDRIVE_SUCCESS,
  SET_DOCUMENTO_OPENED,
  RETRIEVE_DOCUMENTO_OPENED,
  UPSERT_DOCUMENTO_OPENED,
  SET_DOCUMENTO_UPLOADED,
  STORE_CHATS_TEAMS,
  RETRIEVE_CHATS_TEAMS,
  RETRIEVE_CHAT_MESSAGES_TEAMS,
  CREATE_CHAT_TEAMS,
  SEND_CHAT_MESSAGE_TEAMS,
  SEND_CHAT_ATTACHMENT_MESSAGE_TEAMS,
  REFRESH_GRAPH_SUBSCRIPTIONS,
  STORE_UPLOADED_ATTACHMENT_TEAMS,
  STORE_NOTIFICATIONS_COUNT_TEAMS,
  RETRIEVE_CHATS,
  SEND_CHAT_ATTACHMENT_MESSAGE,
} from '.';
import {
  CREATE_CHAT,
  CREATE_PROJECT,
  DELETE_CATEGORY,
  DELETE_DOCUMENTS,
  DELETE_FATTURA,
  DELETE_GRUPPO,
  DELETE_ONORARIO_FATTURA,
  DELETE_PROJECT,
  DELETE_RATA_FATTURA,
  DELETE_RELAZIONE_TIPO,
  DELETE_SPESE_GENERALI,
  DELETE_TARIFFA_ORARIA,
  DOWNLOAD_DOCUMENTS,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_DOCUMENTS,
  GET_DOCUMENTS_SUCCESS,
  GET_PROJECTS,
  GET_PROJECTS_DUE_DILIGENCE,
  GET_PROJECTS_DUE_DILIGENCE_SUCCESS,
  GET_PROJECTS_SUCCESS,
  GET_USER_RELATED_DATA,
  GET_USER_RELATED_DATA_SUCCESS,
  INVIA_FATTURA_ELETTRONICA,
  POLL_DOCUMENTS_STATUS,
  RESET_CURRENT_CREATE_SESSION,
  RESET_FILTERS,
  RETRIEVE_CHAT_MESSAGES,
  RETRIEVE_FATTURA,
  RETRIEVE_FATTURA_LIST,
  RETRIEVE_GRUPPO,
  RETRIEVE_GRUPPO_LIST,
  RETRIEVE_NOTULA_LIST,
  RETRIEVE_ONORARIO_FATTURA,
  RETRIEVE_ONORARIO_FATTURA_LIST,
  RETRIEVE_RATA_FATTURA,
  RETRIEVE_RATA_FATTURA_LIST,
  RETRIEVE_RELAZIONE_TIPO_LIST,
  RETRIEVE_SPESE_GENERALI_LIST,
  RETRIEVE_TARIFFA_ORARIA_LIST,
  RETRIEVE_TIMESHEET_CHART_LIST,
  RETRIEVE_TIMESHEET_CHART_LIST_DOWNLOAD,
  RETRIEVE_TIMESHEET_LIST_DOWNLOAD,
  SEARCH_DOCUMENTS,
  SEND_CHAT_MESSAGE,
  SET_CONFIRM_POPUP,
  SET_CURRENT_FATTURA,
  SET_CURRENT_ONORARIO_FATTURA,
  SET_CURRENT_RATA_FATTURA,
  SET_FILTERS,
  SET_LOADERS,
  SET_MODAL_STATE,
  SET_POPUP_INFO,
  SET_PRELOAD,
  SET_SEARCH,
  STORE_CHATS,
  STORE_CURRENT_CREATE_SESSION,
  STORE_CURRENT_MESSAGES,
  STORE_CURRENT_MESSAGES_TEAMS,
  STORE_FATTURA_LIST,
  STORE_GRUPPO_LIST,
  STORE_NOTIFICATIONS_COUNT,
  STORE_NOTULA_LIST,
  STORE_ONORARIO_FATTURA_LIST,
  STORE_RATA_FATTURA_LIST,
  STORE_RELAZIONE_TIPO_LIST,
  STORE_SEARCH_DOCUMENTS,
  STORE_SPESE_GENERALI_LIST,
  STORE_TARIFFA_ORARIA_LIST,
  STORE_TIMESHEET_CHART_LIST,
  STORE_UPLOADED_ATTACHMENT,
  UPLOAD_DOCUMENTS,
  UPSERT_FATTURA,
  UPSERT_GRUPPO,
  UPSERT_ONORARIO_FATTURA,
  UPSERT_RATA_FATTURA,
  UPSERT_RELAZIONE_TIPO,
  UPSERT_SPESE_GENERALI,
  UPSERT_TARIFFA_ORARIA,
} from './data.const';

/*
ACTIONS
*/

export const getUserRelatedData = (payload?: any) => ({
  type: GET_USER_RELATED_DATA,
  payload,
});

export const getUserRelatedDataSuccess = (payload?: any) => ({
  type: GET_USER_RELATED_DATA_SUCCESS,
  payload,
});

export const setFilters = (payload?: any) => ({
  type: SET_FILTERS,
  payload,
});

export const setLoaders = (payload?: any) => ({
  type: SET_LOADERS,
  payload,
});

export const getCategories = (payload?: any) => ({
  type: GET_CATEGORIES,
  payload,
});

export const getCategoriesSuccess = (payload?: any) => ({
  type: GET_CATEGORIES_SUCCESS,
  payload,
});

export const getProjects = (payload?: any) => ({
  type: GET_PROJECTS,
  payload,
});

export const getProjectsSuccess = (payload?: any) => ({
  type: GET_PROJECTS_SUCCESS,
  payload,
});

export const deleteCategory = (payload?: any) => ({
  type: DELETE_CATEGORY,
  payload,
});

export const deleteProject = (payload?: any) => ({
  type: DELETE_PROJECT,
  payload,
});

export const getDocuments = (payload?: any) => ({
  type: GET_DOCUMENTS,
  payload,
});

export const getDocumentsSuccess = (payload?: any) => ({
  type: GET_DOCUMENTS_SUCCESS,
  payload,
});

export const uploadDocuments = (payload?: any) => ({
  type: UPLOAD_DOCUMENTS,
  payload,
});

export const pollDocumentStatus = (payload?: any) => ({
  type: POLL_DOCUMENTS_STATUS,
  payload,
});

export const deleteDocuments = (payload?: any) => ({
  type: DELETE_DOCUMENTS,
  payload,
});

export const downloadDocuments = (payload?: any) => ({
  type: DOWNLOAD_DOCUMENTS,
  payload,
});

export const downloadDocumentsByTypeAction = (payload?: any) => ({
  type: DOWNLOAD_DOCUMENTS_BY_TYPE,
  payload,
});

export const setPopupInfo = (payload?: any) => ({
  type: SET_POPUP_INFO,
  payload,
});

export const setConfirmPopupAction = (payload?: any) => ({
  type: SET_CONFIRM_POPUP,
  payload,
});

export const createProjectAction = (payload?: any) => ({
  type: CREATE_PROJECT,
  payload,
});

export const resetFiltersAction = (payload?: any) => ({
  type: RESET_FILTERS,
  payload,
});

export const searchDocumentsAction = (payload?: any) => ({
  type: SEARCH_DOCUMENTS,
  payload,
});

export const storeSearchDocumentsAction = (payload?: any) => ({
  type: STORE_SEARCH_DOCUMENTS,
  payload,
});

export const modifyDocumentsAction = (payload?: any) => ({
  type: MODIFY_DOCUMENTS,
  payload,
});

// due diligence

export const getDocumentsDueDiligence = (payload?: any) => ({
  type: GET_DOCUMENTS_DUE_DILIGENCE,
  payload,
});

export const getDocumentsDueDiligenceSuccess = (payload?: any) => ({
  type: GET_DOCUMENTS_DUE_DILIGENCE_SUCCESS,
  payload,
});

export const deleteDocumentsDueDiligence = (payload?: any) => ({
  type: DELETE_DOCUMENTS_DUE_DILIGENCE,
  payload,
});

export const downloadDocumentsDueDiligence = (payload?: any) => ({
  type: DOWNLOAD_DOCUMENTS_DUE_DILIGENCE,
  payload,
});

export const getProjectsDueDiligence = (payload?: any) => ({
  type: GET_PROJECTS_DUE_DILIGENCE,
  payload,
});

export const getProjectsDueDiligenceSuccess = (payload?: any) => ({
  type: GET_PROJECTS_DUE_DILIGENCE_SUCCESS,
  payload,
});

export const deleteProjectDueDiligence = (payload?: any) => ({
  type: DELETE_PROJECT_DUE_DILIGENCE,
  payload,
});

export const createProjectDueDiligenceAction = (payload?: any) => ({
  type: CREATE_PROJECT_DUE_DILIGENCE,
  payload,
});

export const uploadDocumentsDueDiligenceAction = (payload?: any) => ({
  type: UPLOAD_DOCUMENTS_DUE_DILIGENCE,
  payload,
});

export const modifyDocumentsDueDiligenceAction = (payload?: any) => ({
  type: MODIFY_DOCUMENTS_DUE_DILIGENCE,
  payload,
});

export const retrieveAccountAction = (payload?: any) => ({
  type: RETRIEVE_ACCOUNT,
  payload,
});

export const upsertAccountAction = (payload?: any) => ({
  type: UPSERT_ACCOUNT,
  payload,
});

export const storeAccountAction = (payload?: any) => ({
  type: STORE_ACCOUNT,
  payload,
});

export const retrieveUserListAction = (payload?: any) => ({
  type: RETRIEVE_USER_LIST,
  payload,
});

export const storeUserListAction = (payload?: any) => ({
  type: STORE_USER_LIST,
  payload,
});

export const deleteUserAction = (payload?: any) => ({
  type: DELETE_USER,
  payload,
});

export const setCurrentUserAction = (payload?: any) => ({
  type: SET_CURRENT_USER,
  payload,
});

export const retrieveUserAction = (payload?: any) => ({
  type: RETRIEVE_USER,
  payload,
});

export const upsertUserAction = (payload?: any) => ({
  type: UPSERT_USER,
  payload,
});

export const setListConfigAction = (payload?: any) => ({
  type: SET_LIST_CONFIG,
  payload,
});

export const retrieveRolesAction = (payload?: any) => ({
  type: RETRIEVE_ROLES,
  payload,
});
export const retrievePermissionsAction = (payload?: any) => ({
  type: RETRIEVE_PEMRMISSIONS,
  payload,
});
export const storeRolesAction = (payload?: any) => ({
  type: STORE_ROLES,
  payload,
});
export const storePermissionsAction = (payload?: any) => ({
  type: STORE_PERMISSIONS,
  payload,
});

// anagrafica
export const retrieveRelazioneTipoListAction = (payload?: any) => ({
  type: RETRIEVE_RELAZIONE_TIPO_LIST,
  payload,
});
export const storeRelazioneTipoListAction = (payload?: any) => ({
  type: STORE_RELAZIONE_TIPO_LIST,
  payload,
});
export const upsertRelazioneTipoAction = (payload?: any) => ({
  type: UPSERT_RELAZIONE_TIPO,
  payload,
});
export const deleteRelazioneTipoAction = (payload?: any) => ({
  type: DELETE_RELAZIONE_TIPO,
  payload,
});
export const retrieveGruppoListAction = (payload?: any) => ({
  type: RETRIEVE_GRUPPO_LIST,
  payload,
});
export const retrieveGruppoAction = (payload?: any) => ({
  type: RETRIEVE_GRUPPO,
  payload,
});
export const storeGruppoListAction = (payload?: any) => ({
  type: STORE_GRUPPO_LIST,
  payload,
});
export const upsertGruppoAction = (payload?: any) => ({
  type: UPSERT_GRUPPO,
  payload,
});
export const deleteGruppoAction = (payload?: any) => ({
  type: DELETE_GRUPPO,
  payload,
});
export const setCurrentGruppoAction = (payload?: any) => ({
  type: SET_CURRENT_GRUPPO,
  payload,
});
export const retrieveNaturaGiuridicaAction = (payload?: any) => ({
  type: RETRIEVE_NATURA_GIURIDICA,
  payload,
});
export const storeNaturaGiuridicaAction = (payload?: any) => ({
  type: STORE_NATURA_GIURIDICA,
  payload,
});
export const retrieveAnagraficaCategoriaAction = (payload?: any) => ({
  type: RETRIEVE_ANAGRAFICA_CATEGORIA,
  payload,
});
export const storeAnagraficaCategoriaAction = (payload?: any) => ({
  type: STORE_ANAGRAFICA_CATEGORIA,
  payload,
});
export const upsertAnagraficaCategoriaAction = (payload?: any) => ({
  type: UPSERT_ANAGRAFICA_CATEGORIA,
  payload,
});
export const deleteAnagraficaCategoriaAction = (payload?: any) => ({
  type: DELETE_ANAGRAFICA_CATEGORIA,
  payload,
});
export const retrieveAnagraficaSpecificaAction = (payload?: any) => ({
  type: RETRIEVE_ANAGRAFICA_SPECIFICA,
  payload,
});
export const storeAnagraficaSpecificaAction = (payload?: any) => ({
  type: STORE_ANAGRAFICA_SPECIFICA,
  payload,
});
export const deleteAnagraficaSpecificaAction = (payload?: any) => ({
  type: DELETE_ANAGRAFICA_SPECIFICA,
  payload,
});
export const upsertAnagraficaSpecificaAction = (payload?: any) => ({
  type: UPSERT_ANAGRAFICA_SPECIFICA,
  payload,
});
export const retrieveAnagraficaListAction = (payload?: any) => ({
  type: RETRIEVE_ANAGRAFICA_LIST,
  payload,
});
export const storeAnagraficaListAction = (payload?: any) => ({
  type: STORE_ANAGRAFICA_LIST,
  payload,
});
export const setCurrentAnagraficaAction = (payload?: any) => ({
  type: SET_CURRENT_ANAGRAFICA,
  payload,
});
export const deleteAnagraficaAction = (payload?: any) => ({
  type: DELETE_ANAGRAFICA,
  payload,
});
export const retrieveAnagraficaAction = (payload?: any) => ({
  type: RETRIEVE_ANAGRAFICA,
  payload,
});
export const upsertAnagraficaAction = (payload?: any) => ({
  type: UPSERT_ANAGRAFICA,
  payload,
});
// pratiche
export const retrievePraticaMateriaAction = (payload?: any) => ({
  type: RETRIEVE_PRATICA_MATERIA,
  payload,
});
export const storePraticaMateriaAction = (payload?: any) => ({
  type: STORE_PRATICA_MATERIA,
  payload,
});
export const retrievePraticaListAction = (payload?: any) => ({
  type: RETRIEVE_PRATICA_LIST,
  payload,
});
export const storePraticaListAction = (payload?: any) => ({
  type: STORE_PRATICA_LIST,
  payload,
});
export const setCurrentPraticaAction = (payload?: any) => ({
  type: SET_CURRENT_PRATICA,
  payload,
});
export const deletePraticaAction = (payload?: any) => ({
  type: DELETE_PRATICA,
  payload,
});
export const retrievePraticaAction = (payload?: any) => ({
  type: RETRIEVE_PRATICA,
  payload,
});
export const upsertPraticaAction = (payload?: any) => ({
  type: UPSERT_PRATICA,
  payload,
});
export const upsertPraticaOnedriveAction = (payload?: any) => ({
  type: UPSERT_PRATICA_ONEDRIVE,
  payload,
});
// incarichi
export const retrieveIncaricoListAction = (payload?: any) => ({
  type: RETRIEVE_INCARICO_LIST,
  payload,
});
export const storeIncaricoListAction = (payload?: any) => ({
  type: STORE_INCARICO_LIST,
  payload,
});
export const setCurrentIncaricoAction = (payload?: any) => ({
  type: SET_CURRENT_INCARICO,
  payload,
});
export const deleteIncaricoAction = (payload?: any) => ({
  type: DELETE_INCARICO,
  payload,
});
export const retrieveIncaricoAction = (payload?: any) => ({
  type: RETRIEVE_INCARICO,
  payload,
});
export const upsertIncaricoAction = (payload?: any) => ({
  type: UPSERT_INCARICO,
  payload,
});
// dipartimento
export const retrieveDipartimentoListAction = (payload?: any) => ({
  type: RETRIEVE_DIPARTIMENTO_LIST,
  payload,
});
export const storeDipartimentoListAction = (payload?: any) => ({
  type: STORE_DIPARTIMENTO_LIST,
  payload,
});
export const upsertDipartimentoAction = (payload?: any) => ({
  type: UPSERT_DIPARTIMENTO,
  payload,
});
export const deleteDipartimentoAction = (payload?: any) => ({
  type: DELETE_DIPARTIMENTO,
  payload,
});
// timesheet
export const retrieveTimesheetListAction = (payload?: any) => ({
  type: RETRIEVE_TIMESHEET_LIST,
  payload,
});
export const retrieveTimesheetListDownloadAction = (payload?: any) => ({
  type: RETRIEVE_TIMESHEET_LIST_DOWNLOAD,
  payload,
});
export const storeTimesheetListAction = (payload?: any) => ({
  type: STORE_TIMESHEET_LIST,
  payload,
});
export const setCurrentTimesheetAction = (payload?: any) => ({
  type: SET_CURRENT_TIMESHEET,
  payload,
});
export const deleteTimesheetAction = (payload?: any) => ({
  type: DELETE_TIMESHEET,
  payload,
});
export const retrieveTimesheetAction = (payload?: any) => ({
  type: RETRIEVE_TIMESHEET,
  payload,
});
export const upsertTimesheetAction = (payload?: any) => ({
  type: UPSERT_TIMESHEET,
  payload,
});
export const retrieveTimesheetTipoListAction = (payload?: any) => ({
  type: RETRIEVE_TIMESHEET_TIPO_LIST,
  payload,
});
export const storeTimesheetTipoListAction = (payload?: any) => ({
  type: STORE_TIMESHEET_TIPO_LIST,
  payload,
});
export const upsertTimesheetTipoAction = (payload?: any) => ({
  type: UPSERT_TIMESHEET_TIPO,
  payload,
});
export const deleteTimesheetTipoAction = (payload?: any) => ({
  type: DELETE_TIMESHEET_TIPO,
  payload,
});
// evento
export const retrieveEventoListAction = (payload?: any) => ({
  type: RETRIEVE_EVENTO_LIST,
  payload,
});
export const storeEventoListAction = (payload?: any) => ({
  type: STORE_EVENTO_LIST,
  payload,
});
export const setCurrentEventoAction = (payload?: any) => ({
  type: SET_CURRENT_EVENTO,
  payload,
});
export const deleteEventoAction = (payload?: any) => ({
  type: DELETE_EVENTO,
  payload,
});
export const retrieveEventoAction = (payload?: any) => ({
  type: RETRIEVE_EVENTO,
  payload,
});
export const upsertEventoAction = (payload?: any) => ({
  type: UPSERT_EVENTO,
  payload,
});
export const retrieveEventoTipoListAction = (payload?: any) => ({
  type: RETRIEVE_EVENTO_TIPO_LIST,
  payload,
});
export const storeEventoTipoListAction = (payload?: any) => ({
  type: STORE_EVENTO_TIPO_LIST,
  payload,
});
export const upsertEventoTipoAction = (payload?: any) => ({
  type: UPSERT_EVENTO_TIPO,
  payload,
});
export const deleteEventoTipoAction = (payload?: any) => ({
  type: DELETE_EVENTO_TIPO,
  payload,
});
// documento
export const retrieveDocumentoListAction = (payload?: any) => ({
  type: RETRIEVE_DOCUMENTO_LIST,
  payload,
});
export const storeDocumentoListAction = (payload?: any) => ({
  type: STORE_DOCUMENTO_LIST,
  payload,
});
export const setCurrentDocumentoAction = (payload?: any) => ({
  type: SET_CURRENT_DOCUMENTO,
  payload,
});
export const setDocumentoOpenedAction = (payload?: any) => ({
  type: SET_DOCUMENTO_OPENED,
  payload,
});
export const deleteDocumentoAction = (payload?: any) => ({
  type: DELETE_DOCUMENTO,
  payload,
});
export const retrieveDocumentoAction = (payload?: any) => ({
  type: RETRIEVE_DOCUMENTO,
  payload,
});
export const retrieveDocumentoOpenedAction = (payload?: any) => ({
  type: RETRIEVE_DOCUMENTO_OPENED,
  payload,
});
export const upsertDocumentoOpenedAction = (payload?: any) => ({
  type: UPSERT_DOCUMENTO_OPENED,
  payload,
});
export const upsertDocumentoAction = (payload?: any) => ({
  type: UPSERT_DOCUMENTO,
  payload,
});
export const upsertDocumentoOnedriveAction = (payload?: any) => ({
  type: UPSERT_DOCUMENTO_ONEDRIVE,
  payload,
});
export const upsertDocumentoOnedriveSuccessAction = (payload?: any) => ({
  type: UPSERT_DOCUMENTO_ONEDRIVE_SUCCESS,
  payload,
});
export const deleteDocumentoOnedriveAction = (payload?: any) => ({
  type: DELETE_DOCUMENTO_ONEDRIVE,
  payload,
});
export const sendDocumentoAction = (payload?: any) => ({
  type: SEND_DOCUMENTO,
  payload,
});
export const retrieveDocumentoTipoListAction = (payload?: any) => ({
  type: RETRIEVE_DOCUMENTO_TIPO_LIST,
  payload,
});
export const storeDocumentoTipoListAction = (payload?: any) => ({
  type: STORE_DOCUMENTO_TIPO_LIST,
  payload,
});
export const upsertDocumentoTipoAction = (payload?: any) => ({
  type: UPSERT_DOCUMENTO_TIPO,
  payload,
});
export const deleteDocumentoTipoAction = (payload?: any) => ({
  type: DELETE_DOCUMENTO_TIPO,
  payload,
});
export const retrieveDocumentoCategoriaListAction = (payload?: any) => ({
  type: RETRIEVE_DOCUMENTO_CATEGORIA_LIST,
  payload,
});
export const storeDocumentoCategoriaListAction = (payload?: any) => ({
  type: STORE_DOCUMENTO_CATEGORIA_LIST,
  payload,
});
export const downloadDocumentoAction = (payload?: any) => ({
  type: DOWNLOAD_DOCUMENTO,
  payload,
});
export const uploadDocumentoAction = (payload?: any) => ({
  type: UPLOAD_DOCUMENTO,
  payload,
});
// general
export const storeCurrentCreateSessionAction = (payload?: any) => ({
  type: STORE_CURRENT_CREATE_SESSION,
  payload,
});
export const resetCurrentCreateSessionAction = () => ({
  type: RESET_CURRENT_CREATE_SESSION,
});
// role
export const upsertRoleAction = (payload?: any) => ({
  type: UPSERT_ROLE,
  payload,
});
export const deleteRoleAction = (payload?: any) => ({
  type: DELETE_ROLE,
  payload,
});
export const setCurrentRoleAction = (payload?: any) => ({
  type: SET_CURRENT_ROLE,
  payload,
});
export const retrieveRoleAction = (payload?: any) => ({
  type: RETRIEVE_ROLE,
  payload,
});
// onorario
export const retrieveTariffaOrariaListAction = (payload?: any) => ({
  type: RETRIEVE_TARIFFA_ORARIA_LIST,
  payload,
});
export const storeTariffaOrariaListAction = (payload?: any) => ({
  type: STORE_TARIFFA_ORARIA_LIST,
  payload,
});
export const upsertTariffaOrariaAction = (payload?: any) => ({
  type: UPSERT_TARIFFA_ORARIA,
  payload,
});
export const deleteTariffaOrariaAction = (payload?: any) => ({
  type: DELETE_TARIFFA_ORARIA,
  payload,
});
export const retrieveOnorarioListAction = (payload?: any) => ({
  type: RETRIEVE_ONORARIO_LIST,
  payload,
});
export const storeOnorarioListAction = (payload?: any) => ({
  type: STORE_ONORARIO_LIST,
  payload,
});
export const setCurrentOnorarioAction = (payload?: any) => ({
  type: SET_CURRENT_ONORARIO,
  payload,
});
export const deleteOnorarioAction = (payload?: any) => ({
  type: DELETE_ONORARIO,
  payload,
});
export const retrieveOnorarioAction = (payload?: any) => ({
  type: RETRIEVE_ONORARIO,
  payload,
});
export const upsertOnorarioAction = (payload?: any) => ({
  type: UPSERT_ONORARIO,
  payload,
});
export const retrieveRataListAction = (payload?: any) => ({
  type: RETRIEVE_RATA_LIST,
  payload,
});
export const storeRataListAction = (payload?: any) => ({
  type: STORE_RATA_LIST,
  payload,
});
export const setCurrentRataAction = (payload?: any) => ({
  type: SET_CURRENT_RATA,
  payload,
});
export const deleteRataAction = (payload?: any) => ({
  type: DELETE_RATA,
  payload,
});
export const retrieveRataAction = (payload?: any) => ({
  type: RETRIEVE_RATA,
  payload,
});
export const upsertRataAction = (payload?: any) => ({
  type: UPSERT_RATA,
  payload,
});
// onorario incarico
export const retrieveOnorarioIncaricoListAction = (payload?: any) => ({
  type: RETRIEVE_ONORARIO_INCARICO_LIST,
  payload,
});
export const storeOnorarioIncaricoListAction = (payload?: any) => ({
  type: STORE_ONORARIO_INCARICO_LIST,
  payload,
});
export const setCurrentOnorarioIncaricoAction = (payload?: any) => ({
  type: SET_CURRENT_ONORARIO_INCARICO,
  payload,
});
export const deleteOnorarioIncaricoAction = (payload?: any) => ({
  type: DELETE_ONORARIO_INCARICO,
  payload,
});
export const retrieveOnorarioIncaricoAction = (payload?: any) => ({
  type: RETRIEVE_ONORARIO_INCARICO,
  payload,
});
export const upsertOnorarioIncaricoAction = (payload?: any) => ({
  type: UPSERT_ONORARIO_INCARICO,
  payload,
});
export const retrieveRataIncaricoListAction = (payload?: any) => ({
  type: RETRIEVE_RATA_INCARICO_LIST,
  payload,
});
export const storeRataIncaricoListAction = (payload?: any) => ({
  type: STORE_RATA_INCARICO_LIST,
  payload,
});
export const setCurrentRataIncaricoAction = (payload?: any) => ({
  type: SET_CURRENT_RATA_INCARICO,
  payload,
});
export const deleteRataIncaricoAction = (payload?: any) => ({
  type: DELETE_RATA_INCARICO,
  payload,
});
export const retrieveRataIncaricoAction = (payload?: any) => ({
  type: RETRIEVE_RATA_INCARICO,
  payload,
});
export const upsertRataIncaricoAction = (payload?: any) => ({
  type: UPSERT_RATA_INCARICO,
  payload,
});
// search
export const setSearchAction = (payload?: any) => ({
  type: SET_SEARCH,
  payload,
});
export const setPreloadAction = (payload?: any) => ({
  type: SET_PRELOAD,
  payload,
});
// fattura
export const retrieveSpeseGeneraliListAction = (payload?: any) => ({
  type: RETRIEVE_SPESE_GENERALI_LIST,
  payload,
});
export const storeSpeseGeneraliListAction = (payload?: any) => ({
  type: STORE_SPESE_GENERALI_LIST,
  payload,
});
export const upsertSpeseGeneraliAction = (payload?: any) => ({
  type: UPSERT_SPESE_GENERALI,
  payload,
});
export const deleteSpeseGeneraliAction = (payload?: any) => ({
  type: DELETE_SPESE_GENERALI,
  payload,
});
export const retrieveFatturaListAction = (payload?: any) => ({
  type: RETRIEVE_FATTURA_LIST,
  payload,
});
export const storeFatturaListAction = (payload?: any) => ({
  type: STORE_FATTURA_LIST,
  payload,
});
export const setCurrentFatturaAction = (payload?: any) => ({
  type: SET_CURRENT_FATTURA,
  payload,
});
export const deleteFatturaAction = (payload?: any) => ({
  type: DELETE_FATTURA,
  payload,
});
export const retrieveFatturaAction = (payload?: any) => ({
  type: RETRIEVE_FATTURA,
  payload,
});
export const upsertFatturaAction = (payload?: any) => ({
  type: UPSERT_FATTURA,
  payload,
});
export const retrieveNotulaListAction = (payload?: any) => ({
  type: RETRIEVE_NOTULA_LIST,
  payload,
});
export const storeNotulaListAction = (payload?: any) => ({
  type: STORE_NOTULA_LIST,
  payload,
});
// onorario fattura
export const retrieveOnorarioFatturaListAction = (payload?: any) => ({
  type: RETRIEVE_ONORARIO_FATTURA_LIST,
  payload,
});
export const storeOnorarioFatturaListAction = (payload?: any) => ({
  type: STORE_ONORARIO_FATTURA_LIST,
  payload,
});
export const setCurrentOnorarioFatturaAction = (payload?: any) => ({
  type: SET_CURRENT_ONORARIO_FATTURA,
  payload,
});
export const deleteOnorarioFatturaAction = (payload?: any) => ({
  type: DELETE_ONORARIO_FATTURA,
  payload,
});
export const retrieveOnorarioFatturaAction = (payload?: any) => ({
  type: RETRIEVE_ONORARIO_FATTURA,
  payload,
});
export const upsertOnorarioFatturaAction = (payload?: any) => ({
  type: UPSERT_ONORARIO_FATTURA,
  payload,
});
export const retrieveRataFatturaListAction = (payload?: any) => ({
  type: RETRIEVE_RATA_FATTURA_LIST,
  payload,
});
export const storeRataFatturaListAction = (payload?: any) => ({
  type: STORE_RATA_FATTURA_LIST,
  payload,
});
export const setCurrentRataFatturaAction = (payload?: any) => ({
  type: SET_CURRENT_RATA_FATTURA,
  payload,
});
export const deleteRataFatturaAction = (payload?: any) => ({
  type: DELETE_RATA_FATTURA,
  payload,
});
export const retrieveRataFatturaAction = (payload?: any) => ({
  type: RETRIEVE_RATA_FATTURA,
  payload,
});
export const upsertRataFatturaAction = (payload?: any) => ({
  type: UPSERT_RATA_FATTURA,
  payload,
});
// timesheet chart
export const retrieveTimesheetChartListAction = (payload?: any) => ({
  type: RETRIEVE_TIMESHEET_CHART_LIST,
  payload,
});
export const retrieveTimesheetChartListDownloadAction = (payload?: any) => ({
  type: RETRIEVE_TIMESHEET_CHART_LIST_DOWNLOAD,
  payload,
});
export const storeTimesheetChartListAction = (payload?: any) => ({
  type: STORE_TIMESHEET_CHART_LIST,
  payload,
});
// fatturazione elettronica
export const inviaFatturaElettronicaAction = (payload?: any) => ({
  type: INVIA_FATTURA_ELETTRONICA,
  payload,
});
// visure
export const retrieveVisurePricesAction = (payload?: any) => ({
  type: RETRIEVE_VISURE_PRICES,
  payload,
});
export const storeVisurePricesAction = (payload?: any) => ({
  type: STORE_VISURE_PRICES,
  payload,
});
// deposito
export const retrieveDepositoListAction = (payload?: any) => ({
  type: RETRIEVE_DEPOSITO_LIST,
  payload,
});
export const storeDepositoListAction = (payload?: any) => ({
  type: STORE_DEPOSITO_LIST,
  payload,
});
export const setCurrentDepositoAction = (payload?: any) => ({
  type: SET_CURRENT_DEPOSITO,
  payload,
});
export const deleteDepositoAction = (payload?: any) => ({
  type: DELETE_DEPOSITO,
  payload,
});
export const retrieveDepositoAction = (payload?: any) => ({
  type: RETRIEVE_DEPOSITO,
  payload,
});
export const upsertDepositoAction = (payload?: any) => ({
  type: UPSERT_DEPOSITO,
  payload,
});
// notifica
export const retrieveNotificaListAction = (payload?: any) => ({
  type: RETRIEVE_NOTIFICA_LIST,
  payload,
});
export const storeNotificaListAction = (payload?: any) => ({
  type: STORE_NOTIFICA_LIST,
  payload,
});
export const setCurrentNotificaAction = (payload?: any) => ({
  type: SET_CURRENT_NOTIFICA,
  payload,
});
export const deleteNotificaAction = (payload?: any) => ({
  type: DELETE_NOTIFICA,
  payload,
});
export const retrieveNotificaAction = (payload?: any) => ({
  type: RETRIEVE_NOTIFICA,
  payload,
});
export const upsertNotificaAction = (payload?: any) => ({
  type: UPSERT_NOTIFICA,
  payload,
});
// configuration
export const retrieveConfigurationListAction = (payload?: any) => ({
  type: RETRIEVE_CONFIGURATION_LIST,
  payload,
});
export const storeConfigurationListAction = (payload?: any) => ({
  type: STORE_CONFIGURATION_LIST,
  payload,
});
export const setCurrentConfigurationAction = (payload?: any) => ({
  type: SET_CURRENT_CONFIGURATION,
  payload,
});
export const deleteConfigurationAction = (payload?: any) => ({
  type: DELETE_CONFIGURATION,
  payload,
});
export const retrieveConfigurationAction = (payload?: any) => ({
  type: RETRIEVE_CONFIGURATION,
  payload,
});
export const upsertConfigurationAction = (payload?: any) => ({
  type: UPSERT_CONFIGURATION,
  payload,
});
// modal
export const setModalStateAction = (payload?: any) => ({
  type: SET_MODAL_STATE,
  payload,
});

export const storeUnreadNotices = (payload?: any) => ({
  type: STORE_UNREAD_NOTICES,
  payload,
});
// spesa
export const retrieveSpesaCategoriaListAction = (payload?: any) => ({
  type: RETRIEVE_SPESA_CATEGORIA_LIST,
  payload,
});
export const storeSpesaCategoriaListAction = (payload?: any) => ({
  type: STORE_SPESA_CATEGORIA_LIST,
  payload,
});
export const upsertSpesaCategoriaAction = (payload?: any) => ({
  type: UPSERT_SPESA_CATEGORIA,
  payload,
});
export const deleteSpesaCategoriaAction = (payload?: any) => ({
  type: DELETE_SPESA_CATEGORIA,
  payload,
});
export const retrieveSpesaListAction = (payload?: any) => ({
  type: RETRIEVE_SPESA_LIST,
  payload,
});
export const storeSpesaListAction = (payload?: any) => ({
  type: STORE_SPESA_LIST,
  payload,
});
export const setCurrentSpesaAction = (payload?: any) => ({
  type: SET_CURRENT_SPESA,
  payload,
});
export const deleteSpesaAction = (payload?: any) => ({
  type: DELETE_SPESA,
  payload,
});
export const retrieveSpesaAction = (payload?: any) => ({
  type: RETRIEVE_SPESA,
  payload,
});
export const upsertSpesaAction = (payload?: any) => ({
  type: UPSERT_SPESA,
});

// microsoft
export const storeMicrosoftAccountStatus = (payload?: any) => ({
  type: STORE_MICROSOFT_ACCOUNT_STATUS,
  payload,
});

export const storeMicrosoftProfileStatus = (payload?: any) => ({
  type: STORE_MICROSOFT_PROFILE_STATUS,
  payload,
});

export const storeCanConfigureMicrosoft = (payload?: any) => ({
  type: STORE_CAN_CONFIGURE_MICROSOFT,
  payload,
});

export const refreshGraphSubscriptionsAction = (payload?: any) => ({
  type: REFRESH_GRAPH_SUBSCRIPTIONS,
  payload,
});

export const retrieveCalendarsAction = (payload?: any) => ({
  type: RETRIEVE_CALENDARS_OUTLOOK,
  payload,
});
export const createCalendarAction = (payload?: any) => ({
  type: CREATE_CALENDAR_OUTLOOK,
  payload,
});
export const deleteCalendarAction = (payload?: any) => ({
  type: DELETE_CALENDAR_OUTLOOK,
  payload,
});
export const retrieveCalendarPermissionsAction = (payload?: any) => ({
  type: RETRIEVE_CALENDAR_PERMISSIONS_OUTLOOK,
  payload,
});
export const retrieveCalendarScheduleAction = (payload?: any) => ({
  type: RETRIEVE_CALENDAR_SCHEDULE_OUTLOOK,
  payload,
});
export const createCalendarPermissionAction = (payload?: any) => ({
  type: CREATE_CALENDAR_PERMISSION_OUTLOOK,
  payload,
});
export const deleteCalendarPermissionAction = (payload?: any) => ({
  type: DELETE_CALENDAR_PERMISSION_OUTLOOK,
  payload,
});
export const storeCalendars = (payload?: any) => ({
  type: STORE_CALENDARS_OUTLOOK,
  payload,
});
export const storeCalendarPermissions = (payload?: any) => ({
  type: STORE_CALENDAR_PERMISSIONS_OUTLOOK,
  payload,
});
export const storeCalendarSchedule = (payload?: any) => ({
  type: STORE_CALENDAR_SCHEDULE_OUTLOOK,
  payload,
});

export const retrieveTeamsChatsAction = (payload?: any) => ({
  type: RETRIEVE_CHATS_TEAMS,
  payload,
});
export const storeTeamsChats = (payload?: any) => ({
  type: STORE_CHATS_TEAMS,
  payload,
});
export const createTeamsChatAction = (payload?: any) => ({
  type: CREATE_CHAT_TEAMS,
  payload,
});
export const sendTeamsChatMessageAction = (payload?: any) => ({
  type: SEND_CHAT_MESSAGE_TEAMS,
  payload,
});
export const sendTeamsChatAttachmentMessageAction = (payload?: any) => ({
  type: SEND_CHAT_ATTACHMENT_MESSAGE_TEAMS,
  payload,
});
export const retrieveTeamsChatMessagesAction = (payload?: any) => ({
  type: RETRIEVE_CHAT_MESSAGES_TEAMS,
  payload,
});
export const storeCurrentTeamsMessagesAction = (payload?: any) => ({
  type: STORE_CURRENT_MESSAGES_TEAMS,
  payload,
});
export const storeTeamsUploadedAttachmentAction = (payload?: any) => ({
  type: STORE_UPLOADED_ATTACHMENT_TEAMS,
  payload,
});
export const storeTeamsNotificationsCountAction = (payload?: any) => ({
  type: STORE_NOTIFICATIONS_COUNT_TEAMS,
  payload,
});

// api core
export const loadFromCloudAction = (payload?: any) => ({
  type: LOAD_FROM_CLOUD,
  payload,
});
export const saveToCloudAction = (payload?: any) => ({
  type: SAVE_TO_CLOUD,
  payload,
});
export const exportPdfAction = (payload?: any) => ({
  type: EXPORT_PDF,
  payload,
});
export const addBookmarkToPdfAction = (payload?: any) => ({
  type: ADD_BOOKMARK_TO_PDF,
  payload,
});
export const setCurrentCloudDocumentAction = (payload?: any) => ({
  type: SET_CURRENT_CLOUD_DOCUMENT,
  payload,
});

export const setDocumentoUploadedAction = (payload?: any) => ({
  type: SET_DOCUMENTO_UPLOADED,
  payload,
});

// chat
export const retrieveChatsAction = (payload?: any) => ({
  type: RETRIEVE_CHATS,
  payload,
});
export const storeChats = (payload?: any) => ({
  type: STORE_CHATS,
  payload,
});
export const createChatAction = (payload?: any) => ({
  type: CREATE_CHAT,
  payload,
});
export const sendChatMessageAction = (payload?: any) => ({
  type: SEND_CHAT_MESSAGE,
  payload,
});
export const sendChatAttachmentMessageAction = (payload?: any) => ({
  type: SEND_CHAT_ATTACHMENT_MESSAGE,
  payload,
});
export const retrieveChatMessagesAction = (payload?: any) => ({
  type: RETRIEVE_CHAT_MESSAGES,
  payload,
});
export const storeCurrentChatMessagesAction = (payload?: any) => ({
  type: STORE_CURRENT_MESSAGES,
  payload,
});
export const storeChatUploadedAttachmentAction = (payload?: any) => ({
  type: STORE_UPLOADED_ATTACHMENT,
  payload,
});
export const storeChatNotificationsCountAction = (payload?: any) => ({
  type: STORE_NOTIFICATIONS_COUNT,
  payload,
});
