import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../../services/http-service';
import { setLoaders, setPopupInfo } from '../../../data.action';
import {
  CREATE_CALENDAR_PERMISSION_OUTLOOK,
  RETRIEVE_CALENDAR_PERMISSIONS_OUTLOOK,
} from '../../../data.const';

const createCalendarPermissionOutlookEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(CREATE_CALENDAR_PERMISSION_OUTLOOK),
    map(({ payload }: any) => {
      const { calendarId, body } = payload;
      return { body, id: calendarId };
    }),
    // tap(p => console.log(`[epic ${CREATE_CALENDAR_PERMISSION_OUTLOOK}]`, p)),
    withLatestFrom(state$),
    switchMap(([{ body, id }, _state]) => {
      return concat(
        of(setLoaders({ calendarsLoader: true })),
        defer(() =>
          from(
            request({
              path: `microsoft/calendars/${id}/permissions`,
              method: httpMethods.POST,
              body,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message ?? p.errorBody);
              }
              return p?.result;
            }),
            tap((p) =>
              console.log(
                `[epic ${CREATE_CALENDAR_PERMISSION_OUTLOOK}] Created permission:`,
                p
              )
            ),
            map((_p) => {
              return {
                type: RETRIEVE_CALENDAR_PERMISSIONS_OUTLOOK,
                payload: id,
              };
            }),
            catchError((err) => {
              console.error(
                `[epic ${CREATE_CALENDAR_PERMISSION_OUTLOOK}] error`,
                err
              );
              return of(
                setPopupInfo({
                  opened: true,
                  message: err.message ?? 'Si é verificato un errore',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ calendarsLoader: false }))
      );
    })
  );

export default createCalendarPermissionOutlookEpic;
