/* eslint-disable dot-notation */
import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import thunk from 'redux-thunk';
import { merge } from 'lodash';
import rootReducer from './root-reducer';
import { rootEpic } from './root-epic';
import { initialState as authInitialState } from './modules/auth/auth.reducer';
import { initialState as dataInitialState } from './modules/data/data.reducer';

// Function to save specific state variable to local storage
const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify({
      data: {
        teamsNotifications: state.data.teamsNotifications,
      },
    });
    if (typeof window !== 'undefined') {
      localStorage.setItem('reduxState', serializedState);
    }
  } catch (err) {
    console.error('Error saving state:', err);
  }
};

// Function to load specific state variable from local storage
const loadState = () => {
  try {
    let serializedState: string | null = null;
    if (typeof window !== 'undefined') {
      serializedState = localStorage.getItem('reduxState');
    }
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error('Error loading state:', err);
    return undefined;
  }
};

const epicMiddleware = createEpicMiddleware();
const composeEnhancers =
  typeof window !== 'undefined' &&
  ((window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) &&
  process.env.NODE_ENV !== 'production'
    ? ((window as any)[
        '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'
      ] as typeof compose)
    : compose;

// Load state from local storage
const persistedState = loadState();

const store = createStore(
  rootReducer,
  merge(
    rootReducer(
      {
        auth: authInitialState,
        data: dataInitialState,
      },
      {} as any
    ),
    persistedState
  ), // Merge persisted state with initial state
  composeEnhancers(applyMiddleware(thunk, epicMiddleware))
);

// Subscribe to store changes and save specific state variable to local storage
store.subscribe(() => {
  saveState(store.getState());
});

epicMiddleware.run(rootEpic);

// eslint-disable-next-line import/no-anonymous-default-export
export default { store };
