import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { setLoaders, setPopupInfo } from '../../data.action';
import { CREATE_CHAT, RETRIEVE_CHATS } from '../../data.const';

const createChatEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(CREATE_CHAT),
    map(({ payload }: any) => payload),
    // tap(p => console.log(`[epic ${CREATE_CHAT}]`, p)),
    withLatestFrom(state$),
    switchMap(([body, _state]) => {
      return concat(
        of(setLoaders({ chatsLoader: true })),
        defer(() =>
          from(
            request({
              path: `chats`,
              method: httpMethods.POST,
              body,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return p?.result;
            }),
            tap((p) => console.log(`[epic ${CREATE_CHAT}] Created chat:`, p)),
            map((_p) => {
              return {
                type: RETRIEVE_CHATS,
                payload: null,
              };
            }),
            catchError((err) => {
              console.error(`[epic ${CREATE_CHAT}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: 'Si é verificato un errore',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ chatsLoader: false }))
      );
    })
  );

export default createChatEpic;
