import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../../services/http-service';
import { setLoaders, setPopupInfo } from '../../../data.action';
import { CREATE_CHAT_TEAMS, RETRIEVE_CHATS_TEAMS } from '../../../data.const';

const createChatTeamsEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(CREATE_CHAT_TEAMS),
    map(({ payload }: any) => payload),
    // tap(p => console.log(`[epic ${CREATE_CHAT_TEAMS}]`, p)),
    withLatestFrom(state$),
    switchMap(([body, _state]) => {
      return concat(
        of(setLoaders({ chatsLoader: true })),
        defer(() =>
          from(
            request({
              path: `microsoft/chats`,
              method: httpMethods.POST,
              body,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message ?? p.errorBody);
              }
              return p?.result;
            }),
            tap((p) =>
              console.log(`[epic ${CREATE_CHAT_TEAMS}] Created chat:`, p)
            ),
            map((_p) => {
              return {
                type: RETRIEVE_CHATS_TEAMS,
                payload: null,
              };
            }),
            catchError((err) => {
              console.error(`[epic ${CREATE_CHAT_TEAMS}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: err.message ?? 'Si é verificato un errore',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ chatsLoader: false }))
      );
    })
  );

export default createChatTeamsEpic;
