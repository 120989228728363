import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { concat, defer, EMPTY, from, of } from 'rxjs';
import { httpMethods, request } from '../../../../../../services/http-service';
import { UPSERT_DOCUMENTO_ONEDRIVE } from '../../../data.const';
import {
  setLoaders,
  setPopupInfo,
  upsertDocumentoOnedriveSuccessAction,
} from '../../../data.action';

const upsertDocumentoOnedriveEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UPSERT_DOCUMENTO_ONEDRIVE),
    map(({ payload }: any) => {
      const { body, canNavigate } = payload;
      const { createdAt, updatedAt, ...rest } = body;
      if (rest.id === 'crea') {
        delete rest.id;
      }
      return { body: rest, canNavigate };
    }),
    tap((p) => console.log(`[epic ${UPSERT_DOCUMENTO_ONEDRIVE}]`, p)),
    withLatestFrom(state$),
    switchMap(([payload, state]) => {
      const { body, canNavigate } = payload;

      if (!state.data.microsoftAccountStatus?.microsoftConfigured) {
        return of(upsertDocumentoOnedriveSuccessAction({ canNavigate }));
      }

      return concat(
        of(setLoaders({ documentoLoader: true, page: true })),
        defer(() =>
          from(
            request({
              path: `microsoft/onedrive/document`,
              method: httpMethods.POST,
              body,
            })
          ).pipe(
            switchMap((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message ?? p.errorBody);
              }
              return EMPTY;
            }),
            catchError((err) => {
              console.error(`[epic ${UPSERT_DOCUMENTO_ONEDRIVE}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: err.message ?? 'Si é verificato un errore',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ documentoLoader: false, page: false })),
        of(upsertDocumentoOnedriveSuccessAction({ canNavigate }))
      );
    })
  );

export default upsertDocumentoOnedriveEpic;
