const appName = 'app';
const prefix = process.env.GATSBY_PREFIX || '';

const domain =
  process.env.GATSBY_DOMAIN ||
  'https://api.nomox.it' ||
  'http://localhost:8000';

const sso_domain = process.env.GATSBY_SSO_DOMAIN || 'https://sso.nomox.it';
const sso_realms =
  process.env.GATSBY_STAGE === 'prd' || process.env.GATSBY_STAGE === 'stg'
    ? 'nomox'
    : 'Test';

const irnerioDocumentsProvider =
  'https://ke8uqcbh54.execute-api.eu-west-1.amazonaws.com/prd';
const dueDiligenceProvider =
  'https://nsa82zpx0c.execute-api.eu-west-1.amazonaws.com/prd';

const generalProvider =
  process.env.GATSBY_GESIONALE_PROVIDER ||
  // 'https://ab2wzhb0mb.execute-api.eu-west-1.amazonaws.com/stg' ||
  // 'https://byyhyygmea.execute-api.eu-west-1.amazonaws.com/prd' ||
  'http://localhost:3000';

export const creditiMap = {
  pec: 0.5,
  visuraCatastale: 3,
  visuraIpocatastale: 30,
};

const microsoft_config = {
  clientId: '4e837dfe-1136-4d1a-83a5-9631a13de3f4',
  tenantId: '18cd8753-4ca7-4101-9a8f-b79572efc61c',
  clientSecret: 'ima8Q~s6gy10XfXWRykCaKIEJ5mUXwTXKlm8kbss',
  redirectUri: 'https://sso.nomox.it/realms/Test/broker/microsoft/endpoint',
  scope: 'https://graph.microsoft.com/.default offline_access', // retrieved from Azure by clientId
};

// Docker: 'http://localhost:8010'
const apiCoreEndpoint =
  process.env.GATSBY_STAGE === 'prd' || process.env.GATSBY_STAGE === 'stg'
    ? 'https://apicore.nomox.it'
    : 'https://localhost:7293';

export const rootConfig = {
  isDebug: !process.env.GATSBY_DOMAIN,
  language: 'it',
  url: `${domain}/api`,
  irnerioDocumentsProvider: `${irnerioDocumentsProvider}`,
  urlDueDiligence: `${dueDiligenceProvider}`,
  endpointGestionale: `${generalProvider}/gestionale`,
  beEndpoint: `${generalProvider}`,
  apiCoreEndpoint: `${apiCoreEndpoint}`,
  documentEditorEndpoint: `${apiCoreEndpoint}/DocumentEditor`,
  pdfViewerEndpoint: `${apiCoreEndpoint}/PdfViewer`,
  nomoxHubEndpoint: `${apiCoreEndpoint}/notify`,
  endpointDrive: `${generalProvider}/drive`,
  app_base_path: `/${appName}`,
  app_path: `${prefix}/${appName}`,
  upload_url: `${domain}/api/uploadAudio.php`,
  audio_folder: `${domain}/api/uploads/audio/`,
  base_url: `${domain}`,
  wp_url: `${domain}/?rest_route=`,
  sso_endpoint: `${sso_domain}/realms/nomox/protocol/openid-connect`,
  sso_client_id: `nomox${
    process.env.GATSBY_STAGE === 'prd' ? '' : `-${process.env.GATSBY_STAGE}`
  }`,
  sso_login_ms_personal: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${microsoft_config.clientId}&response_type=code&scope=${microsoft_config.scope}`,
  sso_login_ms_business: `https://login.microsoftonline.com/${microsoft_config.tenantId}/oauth2/v2.0/authorize?client_id=${microsoft_config.clientId}&response_type=code&scope=${microsoft_config.scope}`,
  sso_admin_consent: `https://login.microsoftonline.com/common/adminconsent?client_id=${microsoft_config.clientId}`,
  // sso_admin_consent: `https://login.microsoftonline.com/common/adminconsent?client_id=${microsoft_config.clientId}&scope=${microsoft_config.scope}`,
  stage: process.env.GATSBY_STAGE,
  endpointWpGestionale: `${domain}/?rest_route=/gestionale/v1`,
  modalTimeout: 600,
  pageSize: 10,
  populateFields: 101,
  innerPageSize: '95%',
  documentAutoSaveIntervalInMinutes: 1,
};

export const buildLoginBaseUrlForTenant = (tenant: string | null) => {
  return tenant
    ? `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?client_id=${microsoft_config.clientId}&response_type=code&scope=${microsoft_config.scope}`
    : rootConfig.sso_login_ms_business;
};
