/* eslint-disable no-return-assign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import moment from 'moment';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { FormatType } from '@syncfusion/ej2-documenteditor/src/document-editor';
import { SaveType } from '@syncfusion/ej2-spreadsheet';

// requires
require('dayjs/locale/it');

// component functions
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);
dayjs.locale('it');
dayjs.updateLocale('it', {
  weekdays: [
    'Domenica',
    'Lunedì',
    'Martedì',
    'Mercoledì',
    'Giovedì',
    'Venerdì',
    'Sabato',
  ],
});

export const isEmail = (email: string) =>
  email &&
  email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

export const isPasswordOk = (field: string) =>
  field.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/);

export const filler = Array.from({ length: 100 }, () => ({}));

export const getUserPermissions = (user: any) => {
  const permissions: any = {};
  user?.Roles?.forEach((role: any) => {
    role.Permissions.forEach((permission: any) => {
      const { c, r, u, d, s } = permission.RolePermissions || {};
      permissions[permission.nome] = { c, r, u, d, s };
    });
  });

  user?.Permissions?.forEach((permission: any) => {
    const { c, r, u, d, s } = permission.UserPermissions || {};
    permissions[permission.nome] = {
      c: c || permissions?.[permission.nome]?.c,
      r: r || permissions?.[permission.nome]?.r,
      u: u || permissions?.[permission.nome]?.u,
      d: d || permissions?.[permission.nome]?.d,
      s: s || permissions?.[permission.nome]?.s,
    };
  });

  return permissions;
};

export const checkPermissions = (
  permissions: any,
  neededPermissions: string[],
  types: string[],
  { withAll } = { withAll: ['Tutti'] }
) => {
  const permissionsToCheck = [...withAll, ...neededPermissions];
  const result = permissionsToCheck.filter((permissionName) => {
    const permission = permissions[permissionName];
    if (permission) {
      const typeResult = types.filter((type) => {
        const rolePermissionCheck = permission?.[type];
        const userPermissionCheck = permission?.[type];
        return rolePermissionCheck || userPermissionCheck;
      });
      return !!typeResult.length;
    }
    return false;
  });

  return result.length;
};

export const minutesConverter = (m: number) => {
  const hours = m / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return `${rhours || '0'}:${rminutes || '00'}`;
};

export const minutesConverterForTimeInput = (m: number) => {
  const hours = +m / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  let rminutes: string | number = Math.round(minutes);
  rminutes = rminutes < 10 ? `0${rminutes}` : rminutes;
  rminutes = rminutes === '00' ? '' : rminutes;
  return `${rhours || '0'}${rminutes ? ':' : ''}${rminutes}`;
};

export const minutesConverterForTimesheetHead = (m: number) => {
  const hours = +m / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  let rminutes: string | number = Math.round(minutes);
  rminutes = rminutes < 10 ? `0${rminutes}` : rminutes;
  rminutes = rminutes === '00' ? '' : rminutes;
  return `${rhours || '0'}h ${rminutes ? `${rminutes}min` : ''}`;
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getFilenameAndExtension = (value: string) => {
  const filename = value.substring(0, value.lastIndexOf('.'));
  const extension = value.split('.').pop() || '';
  return [filename, extension];
};

export const getFormatType = (extension: string): FormatType => {
  switch (extension) {
    case 'docx':
    case 'Docx':
      return 'Docx';
    case 'txt':
    case 'Txt':
      return 'Txt';
    case 'sfdt':
    case 'Sfdt':
      return 'Sfdt';
    case 'dotx':
    case 'Dotx':
      return 'Dotx';
    default:
      return 'Docx';
  }
};

export const getSaveType = (extension: string): SaveType => {
  switch (extension) {
    case 'xlsx':
    case 'Xlsx':
      return 'Xlsx';
    case 'xls':
    case 'Xls':
      return 'Xls';
    case 'csv':
    case 'Csv':
      return 'Csv';
    case 'pdf':
    case 'Pdf':
      return 'Pdf';
    default:
      return 'Xlsx';
  }
};

export const getMonthName = (month: number) => {
  /*moment.locale('it');
  const monthMoment = moment(month).locale('it');
  return monthMoment.format('MMMM');*/
  switch (month) {
    case 1:
      return 'Gennaio';
    case 2:
      return 'Febbraio';
    case 3:
      return 'Marzo';
    case 4:
      return 'Aprile';
    case 5:
      return 'Maggio';
    case 6:
      return 'Giugno';
    case 7:
      return 'Luglio';
    case 8:
      return 'Agosto';
    case 9:
      return 'Settembre';
    case 10:
      return 'Ottobre';
    case 11:
      return 'Novembre';
    case 12:
      return 'Dicembre';
    default:
      return 'Sconosciuto';
  }
};

export const getMonthProperties = (currentMonth: string) => {
  moment.locale('it');
  const month = moment(currentMonth).locale('it');
  const nextMonthMoment = month.clone();
  const currentMonthMoment = month.clone();
  const daysInMonth = Array.from(
    { length: month.daysInMonth() },
    (_, i) => i + 1
  );
  let finalDaysWithProps: any[] = [];
  const firstDayOfMonthNotFormatted = month.startOf('month');
  const lastDayOfMonthNotFormatted = nextMonthMoment.endOf('month');

  let firstDay: number | string = firstDayOfMonthNotFormatted.format('d');
  firstDay = +firstDay;
  firstDay = firstDay === 0 ? 7 : firstDay;

  let lastDay: number | string = lastDayOfMonthNotFormatted.format('d');
  lastDay = +lastDay;
  lastDay = lastDay === 0 ? 7 : lastDay;

  // previous month
  const previousMonthWeekStartNotFormatted =
    firstDayOfMonthNotFormatted.subtract(1, 'days');
  const previousMonthWeekStart = previousMonthWeekStartNotFormatted.format('D');
  const previousMonthString = previousMonthWeekStartNotFormatted.format('MMM');
  const previousMonthNumber = previousMonthWeekStartNotFormatted.format('MM');
  const previousYearNumber = previousMonthWeekStartNotFormatted.format('YYYY');
  // next month
  const nextMonthWeekStartNotFormatted = lastDayOfMonthNotFormatted.add(
    1,
    'days'
  );
  const nextMonthString = nextMonthWeekStartNotFormatted.format('MMM');
  const nextMonthNumber = nextMonthWeekStartNotFormatted.format('MM');
  const nextYearNumber = nextMonthWeekStartNotFormatted.format('YYYY');
  const currentMonthString = currentMonthMoment.format('MMM');
  const currentMonthNumber = currentMonthMoment.format('MM');
  const currentYearNumber = currentMonthMoment.format('YYYY');

  const daysInMonthWithProps = daysInMonth.map((day: number) => ({
    day,
    date: moment(
      `${currentMonthNumber}/${day}/${currentYearNumber}`,
      'MM/DD/YYYY'
    )
      .utc()
      .toISOString(),
    month: currentMonthNumber,
    monthString: currentMonthString,
    year: currentYearNumber,
    current: true,
    data: [],
  }));

  finalDaysWithProps = daysInMonthWithProps.slice();

  for (
    let i = +previousMonthWeekStart;
    i > +previousMonthWeekStart - (firstDay - 1);
    i--
  ) {
    finalDaysWithProps.unshift({
      day: i,
      date: moment(
        `${previousMonthNumber}/${i}/${previousYearNumber}`,
        'MM/DD/YYYY'
      )
        .utc()
        .toISOString(),
      previous: true,
      year: previousYearNumber,
      month: previousMonthNumber,
      monthString: previousMonthString,
      data: [],
    });
  }

  const finalDaysLen =
    7 - lastDay + finalDaysWithProps.length - (finalDaysWithProps.length - 1);

  for (let i = 1; i < finalDaysLen; i++) {
    finalDaysWithProps.push({
      day: i,
      date: moment(`${nextMonthNumber}/${i}/${nextYearNumber}`, 'MM/DD/YYYY')
        .utc()
        .toISOString(),
      next: true,
      month: nextMonthNumber,
      monthString: nextMonthString,
      year: nextYearNumber,
      data: [],
    });
  }

  return {
    daysInMonth,
    finalDaysWithProps,
    firstDay,
    previousMonthWeekStart,
  };
};

export const downloadUrl = (url: string, name = 'file.png') => {
  // Create a link element
  const link = document.createElement('a');

  // Set link's href to point to the Blob URL
  link.href = url;
  link.target = '_blank';
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(link);
};

export const timeToDecimal = (time: string) => {
  const hoursMinutes = (time || '').split(/[.:]/);
  const hours = parseInt(hoursMinutes[0], 10);
  const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
  return hours + minutes / 60;
};

export const timeDiffToDecimal = (startTime: string, endTime: string) => {
  const start = timeToDecimal(startTime);
  const end = timeToDecimal(endTime);
  return end - start;
};

export const toEurValue = (value: number) => {
  const it = Intl.NumberFormat('it-IT', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return `€ ${it.format(value)}`;
};

export const toPercentValue = (value: number) => {
  const it = Intl.NumberFormat('it-IT', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return `${it.format(value)}%`;
};

export const ivaValue = (value: string) => {
  const it = Intl.NumberFormat('it-IT', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const iva = value.split('-')[1];
  return `${it.format(parseInt(iva, 10))}%`;
};

export const generateFileFromUrl = async (url: string): Promise<File> => {
  const response = await fetch(url, { mode: 'no-cors' });
  const data = await response.blob();
  const filename = url
    .split('?')?.[0]
    ?.substring(url.split('?')?.[0]?.lastIndexOf('/') + 1);
  return new File([data], filename);
};

export const checkPassword = (str) => {
  const re = /^(?=.*\d)(?=.*[!@#$,;\-:.%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
};

export const checkIban = (input) => {
  const CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
    AL: 28,
    BY: 28,
    EG: 29,
    GE: 22,
    IQ: 23,
    LC: 32,
    SC: 31,
    ST: 25,
    SV: 28,
    TL: 23,
    UA: 29,
    VA: 22,
    VG: 24,
    XK: 20,
  };
  const iban = String(input)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest

  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) =>
    (letter.charCodeAt(0) - 55).toString()
  );
  // final check
  return mod97(digits);
};

function mod97(string) {
  let checksum = string.slice(0, 2);
  let fragment;
  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

export function greet(date: dayjs.Dayjs) {
  const hour = Number(date.format('H'));

  const splitAfternoon = 12; //24hr time to split the afternoon
  const splitEvening = 18; //24hr time to split the evening

  const isMorning = hour >= 5 && hour < splitAfternoon;
  const isAfternoon = splitAfternoon <= hour && hour < splitEvening;

  if (isMorning) {
    return 'Buongiorno';
  }
  if (isAfternoon) {
    return 'Buon pomeriggio';
  }

  return 'Buonasera';
}

export function isNumeric(str) {
  if (typeof str != 'string') return false; // we only process strings!

  return (
    // @ts-ignore
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

interface TotaleListino {
  totaleOnorari: string;
  totaleIVA?: string; // Aggiungi eventuali altre chiavi con il '?' se sono opzionali
}
export function calcolaListino(listino): TotaleListino {
  const tipo_fisso = ['Fisso', 'Spesa'];
  const tipo_percentuale = ['Percentuale'];
  const tipo_orario = ['Orario'];
  let totaleListino = 0;
  let totaleOnorari = 0;
  const aliquotaIva = 0;
  let totaleIVA = 0;
  const aliquota_iva_ef = listino.aliquota_iva
    ? listino.aliquota_iva.split('-')[1]
    : 0;
  if (tipo_fisso.includes(listino.tipo)) {
    totaleListino = listino.tariffa;
  } else if (tipo_percentuale.includes(listino.tipo)) {
    totaleListino = listino.tariffa * listino.importo_valore;
  } else if (tipo_orario.includes(listino.tipo)) {
    totaleListino = listino.tariffa * listino.monte_ore;
  } else {
    return { totaleOnorari: '', totaleIVA: '' };
  }

  if (listino.spese_generali && listino.spese_generali > 0) {
    totaleListino *= 1 + listino.spese_generali / 100;
  }
  if (
    listino.tipo_sconto === 'sconto_percentuale' &&
    listino.importo_sconto > 0
  ) {
    totaleListino *= 1 - listino.importo_sconto / 100;
  }
  if (listino.tipo_sconto === 'sconto_valore' && listino.importo_sconto > 0) {
    totaleListino -= listino.importo_sconto;
  }
  totaleOnorari += totaleListino;

  if (listino.imponibile) {
    totaleIVA = totaleOnorari * (aliquota_iva_ef / 100);
  }

  return {
    totaleOnorari: totaleOnorari.toFixed(2),
    totaleIVA: totaleIVA.toFixed(2),
  };
}

export function calcolaTotaleListini(listini: Array<[]>) {
  let totaleTotaleOnorari = 0;
  let totaleTotaleIVA = 0;
  console.log('stronzo1', listini);
  if (listini && listini.length > 0) {
    listini.forEach((listino) => {
      console.log('stronzo', calcolaListino(listino));
      const { totaleOnorari, totaleIVA } = calcolaListino(listino);
      totaleTotaleOnorari += parseFloat(totaleOnorari);
      totaleTotaleIVA += totaleIVA ? parseFloat(totaleIVA) : 0;
    });

    return {
      totaleOnorari: totaleTotaleOnorari.toFixed(2),
      totaleIVA: totaleTotaleIVA.toFixed(2),
    };
  }
  return null;
}

export function calcolaPreventivo(
  listini,
  contributo_cassa,
  ritenuta_acconto,
  imposta_bollo,
  peso = 1
) {
  if (listini?.length > 0) {
    let check = false;
    const val = ['Fisso', 'Spesa'];

    const listini_spese = listini.map((el) => {
      if (!el.tariffa) {
        return (check = true);
      }
      const { order } = el;
      const tariffa = el.tipo_onorario
        ? parseFloat(el.tariffa) * peso
        : parseFloat(el.tariffa);
      const { descrizione } = el;
      const { imponibile } = el;
      const spese_gen =
        el.spese_generali && el.spese_generali > 0
          ? tariffa * (el.spese_generali / 100)
          : 0;
      const spese_gen_perc =
        el.spese_generali && el.spese_generali > 0 ? el.spese_generali : 0;
      const sconto_percentuale =
        (el.tipo_sconto === 'sconto_percentuale' && el.importo_sconto) > 0
          ? (tariffa + spese_gen) * (el.importo_sconto / 100)
          : 0;
      const sconto_valore =
        el.tipo_sconto === 'sconto_valore' && el.importo_sconto > 0
          ? el.importo_sconto
          : 0;
      const sconto =
        sconto_percentuale > 0
          ? sconto_percentuale
          : sconto_valore > 0
          ? sconto_valore
          : 0;
      const totale =
        (contributo_cassa === '1' ||
          contributo_cassa === 1 ||
          contributo_cassa === true) &&
        Boolean(el.imponibile * 1)
          ? (tariffa + spese_gen - sconto) * 1.04
          : tariffa + spese_gen - sconto;
      const totale_imponibile = el.imponibile == 1 ? totale : 0;
      const totale_non_imponibile = el.imponibile == 0 ? totale : 0;
      const aliquota_iva = parseFloat(el?.aliquota_iva?.split('-')[1]) / 100;
      const iva =
        el.imponibile == 1 && aliquota_iva > 0
          ? totale_imponibile * aliquota_iva
          : 0;
      const totale_ivato = totale_imponibile + iva;
      return {
        order,
        descrizione,
        tariffa,
        imponibile,
        spese_gen,
        spese_gen_perc,
        sconto,
        totale_imponibile,
        totale_non_imponibile,
        iva,
        aliquota_iva,
        totale_ivato,
      };
    });
    const ritenuta_acco = ritenuta_acconto
      ? parseFloat(ritenuta_acconto.split('-')[1]) / 100
      : 0;
    const tot_onorari = listini_spese.reduce((acc, oggetto) => {
      if (oggetto.imponibile === 1 || oggetto.imponibile === '1') {
        return acc + oggetto.tariffa;
      }
      return acc;
    }, 0);
    const spese_gen = listini_spese.reduce(
      (acc, oggetto) => acc + oggetto.spese_gen,
      0
    );
    const sconto = listini_spese.reduce(
      (acc, oggetto) => acc + oggetto.sconto,
      0
    );
    const imponibile = listini_spese.reduce(
      (acc, oggetto) => acc + oggetto.totale_imponibile,
      0
    );

    const altre_spese = {
      contributo_cassa:
        contributo_cassa === '1' ||
        contributo_cassa === 1 ||
        contributo_cassa === true
          ? imponibile * 0.04
          : 0,
      ritenuta_acconto:
        ritenuta_acco > 0 ? (tot_onorari + spese_gen) * ritenuta_acco : 0,
      imposta_bollo:
        imposta_bollo === '1' ||
        imposta_bollo === 1 ||
        (imposta_bollo === true && tot_onorari > 77.47)
          ? 2
          : 0,
    };
    const non_imponibile = listini_spese.reduce(
      (acc, oggetto) => acc + oggetto.totale_non_imponibile,
      0
    );
    const iva = listini_spese.reduce((acc, oggetto) => acc + oggetto.iva, 0);
    const spese = [...listini_spese, altre_spese];

    if (check) {
      return {
        pippo: '0',
      };
    }

    return {
      dettagli: spese,
      totaleOnorari: tot_onorari,
      speseGenerali: spese_gen,
      sconto,
      contributoCassa: altre_spese.contributo_cassa,
      totaleImponibile: imponibile,
      totaleIVA: iva,
      totaleNonImponibile: non_imponibile,
      totaleDocumento: imponibile + iva + non_imponibile,
      ritenutaAcconto: altre_spese.ritenuta_acconto,
      impostaDiBollo: altre_spese.imposta_bollo,
      totalePreventivo:
        imponibile +
        iva -
        altre_spese.ritenuta_acconto +
        altre_spese.imposta_bollo +
        non_imponibile,
    };
  }
}

export function calcolaRate(infoRata, tipo_rata = 'rata-pagamento') {
  const {
    metodo_pagamento,
    tipo,
    descrizione,
    data_emissione_standard,
    data_emissione,
    numero_rate,
    pagamento_entro_giorni,
    pagamento_entro_giorni_personalizzato,
    periodicita,
    importo,
    importo_valore_split,
    tipo_valore,
    allocazione_spese,
    importo_spese_imp0,
  } = infoRata;

  const rate: {
    order: number;
    descrizione: string;
    metodo_pagamento: string;
    periodicita: string;
    tipo: string;
    numero_rate: number;
    tipo_valore: string;
    pagamento_entro_giorni: string;
    data_emissione: string;
    data_pagamento: string;
    importo_valore: number;
    tipo_rata: string;
    allocazione_spese: number;
    allocazione_spese_valore: number;
  }[] = [];
  let giorniPagamento: number;
  let num_rate: number;
  let dataEmissione = new Date();
  let peso = 0;
  const oggi = new Date();

  if (pagamento_entro_giorni === 'Personalizzata') {
    giorniPagamento = pagamento_entro_giorni_personalizzato;
  } else {
    giorniPagamento =
      parseInt(pagamento_entro_giorni?.replace('gg', ''), 10) || 0;
  }
  if (data_emissione_standard !== 'Personalizzata') {
    switch (data_emissione_standard) {
      case 'Finemese':
        // Imposta la data al 1° giorno del mese successivo e sottrai un giorno
        dataEmissione = new Date(oggi.getFullYear(), oggi.getMonth() + 1, 1);
        dataEmissione.setDate(dataEmissione.getDate() - 1);
        break;

      case 'Metamese':
        // Verifica se siamo nei primi 15 giorni del mese
        if (oggi.getDate() <= 15) {
          // Se sì, restituisce il 15 del mese corrente
          dataEmissione = new Date(oggi.getFullYear(), oggi.getMonth(), 15);
        } else {
          // Altrimenti restituisce il 15 del mese successivo
          dataEmissione = new Date(oggi.getFullYear(), oggi.getMonth() + 1, 15);
        }
        break;

      case '10delmese':
        // Imposta la data al 10 del mese corrente
        dataEmissione = new Date(oggi.getFullYear(), oggi.getMonth(), 10);
        break;
      default:
        dataEmissione = oggi;
    }
  } else {
    dataEmissione = new Date(data_emissione);
  }
  if (tipo !== 'Periodica') {
    num_rate = 1;
  } else {
    num_rate = numero_rate;
  }
  for (let i = 0; i < num_rate; i++) {
    const nuovaDataEmissione = new Date(dataEmissione);

    // Calcola la nuova data di emissione in base alla periodicita
    if (periodicita === 'Mensile')
      nuovaDataEmissione.setMonth(dataEmissione.getMonth() + i);
    else if (periodicita === 'Bimestrale')
      nuovaDataEmissione.setMonth(dataEmissione.getMonth() + i * 2);
    else if (periodicita === 'Trimestrale')
      nuovaDataEmissione.setMonth(dataEmissione.getMonth() + i * 3);
    else if (periodicita === 'Quadrimestrale')
      nuovaDataEmissione.setMonth(dataEmissione.getMonth() + i * 4);
    else if (periodicita === 'Semestrale')
      nuovaDataEmissione.setMonth(dataEmissione.getMonth() + i * 6);
    else if (periodicita === 'Annuale')
      nuovaDataEmissione.setFullYear(dataEmissione.getFullYear() + i);

    // Calcola la data di pagamento in base ai giorniPagamento
    const dataPagamento = new Date(nuovaDataEmissione);
    dataPagamento.setDate(dataPagamento.getDate() + giorniPagamento);
    if (tipo_valore === 'percentuale' && importo_valore_split == null) {
      peso = 100;
    } else if (tipo_valore === 'valore' && importo_valore_split == null) {
      peso = importo;
    } else {
      peso = importo_valore_split;
    }
    let importo_rata =
      tipo_valore === 'percentuale' ? importo * (peso / 100) : peso;

    if (num_rate > 0) {
      importo_rata = Math.round((importo_rata / num_rate) * 100) / 100; //(importo_rata / num_rate).toFixed(2)
    }

    rate.push({
      order: i + 1,
      descrizione: descrizione || `Piano ${i + 1}`,
      metodo_pagamento,
      data_emissione: nuovaDataEmissione.toISOString(),
      data_pagamento: dataPagamento.toISOString(),
      importo_valore: importo_rata,
      periodicita,
      tipo,
      numero_rate,
      tipo_valore,
      pagamento_entro_giorni,
      tipo_rata,
      allocazione_spese: 0,
      allocazione_spese_valore: 0,
    });
  }
  switch (allocazione_spese) {
    case 'Prima':
      rate[0].allocazione_spese = 1;
      rate[0].allocazione_spese_valore =
        Math.round(importo_spese_imp0 * 100) / 100;
      break;
    case 'Ultima':
      rate[rate.length - 1].allocazione_spese = 1;
      rate[rate.length - 1].allocazione_spese_valore =
        Math.round(importo_spese_imp0 * 100) / 100;
      break;
    case 'EquamenteDistribuite':
      if (num_rate > 0) {
        const quota_allocazione = Math.round((1 / num_rate) * 100) / 100;
        rate.map((rata) => {
          rata.allocazione_spese = 1;
          rata.allocazione_spese_valore =
            Math.round(quota_allocazione * importo_spese_imp0 * 100) / 100;
        });
      } else {
        rate[0].allocazione_spese = 1;
        rate[0].allocazione_spese_valore =
          Math.round(importo_spese_imp0 * 100) / 100;
      }
      break;
    default:
      break;
  }

  return rate;
}

type Periodicit =
  | 'Mensile'
  | 'Bimestrale'
  | 'Trimestrale'
  | 'Semestrale'
  | 'Annuale';

export function calcolaDataFine(
  dataInizio: Date,
  numRinnovi: number,
  periodicit: Periodicit
): string {
  const dataFine: Date = new Date(dataInizio);

  switch (periodicit) {
    case 'Mensile':
      dataFine.setMonth(dataFine.getMonth() * 1 + numRinnovi * 1 + 1);
      break;
    case 'Bimestrale':
      dataFine.setMonth(dataFine.getMonth() * 1 + numRinnovi * 2 + 2);
      break;
    case 'Trimestrale':
      dataFine.setMonth(dataFine.getMonth() * 1 + numRinnovi * 3 + 3);
      break;
    case 'Semestrale':
      dataFine.setMonth(dataFine.getMonth() * 1 + numRinnovi * 6 + 6);
      break;
    case 'Annuale':
      dataFine.setFullYear(dataFine.getFullYear() * 1 + numRinnovi * 1 + 1);
      break;
    default:
      throw new Error('Periodicità non valida');
  }
  const year = dataFine.getFullYear().toString().padStart(4, '0');
  const month = (dataFine.getMonth() + 1).toString().padStart(2, '0');
  const day = dataFine.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function arraysEqual(arr1, arr2) {
  // Verifica se le lunghezze degli array sono diverse
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  // Ordina gli array in base alla proprietà "id" per semplificare il confronto
  const sortedArr1 = arr1?.sort((a, b) => a.id - b.id);
  const sortedArr2 = arr2?.sort((a, b) => a.id - b.id);

  // Confronta ogni elemento degli array
  for (let i = 0; i < sortedArr1?.length; i++) {
    // Confronta le proprietà di ogni oggetto
    for (const key in sortedArr1[i]) {
      if (
        sortedArr1[i].hasOwnProperty(key) &&
        sortedArr2[i].hasOwnProperty(key)
      ) {
        if (sortedArr1[i][key] !== sortedArr2[i][key]) {
          return false;
        }
      }
    }
  }

  // Se non ci sono differenze, gli array sono uguali
  return true;
}

export function calcolaPreventivo2(
  listini,
  cpap = 0,
  ritenuta = '',
  imposta_bollo = 0
) {
  const val = ['Fisso', 'Spesa'];

  const listini_spese = listini?.map((el) => {
    const { order } = el;
    const tariffa = parseFloat(el.tariffa);
    const { descrizione, imponibile } = el;
    const spese_gen =
      el.spese_generali && el.spese_generali > 0 && el.imponibile == 1
        ? tariffa * (el.spese_generali / 100)
        : 0;
    const spese_gen_perc =
      el.spese_generali && el.spese_generali > 0 ? el.spese_generali : 0;
    const sconto_percentuale =
      (el.tipo_sconto === 'sconto_percentuale' && el.importo_sconto) > 0
        ? (tariffa + spese_gen) * (el.importo_sconto / 100)
        : 0;
    const sconto_valore =
      el.tipo_sconto === 'sconto_valore' && el.importo_sconto > 0
        ? el.importo_sconto
        : 0;
    const sconto =
      sconto_percentuale > 0
        ? sconto_percentuale
        : sconto_valore > 0
        ? sconto_valore
        : 0;
    // const totale =
    //   cpap === 1 && Boolean(el.imponibile * 1)
    //     ? (tariffa + spese_gen - sconto) * 1.04
    //     : tariffa + spese_gen - sconto;
    const totale = tariffa + spese_gen - sconto;
    const totale_imponibile =
      el.imponibile == 1 && el.aliquota_iva ? totale : 0;
    const totale_non_imponibile =
      el.imponibile == 0 || !el.aliquota_iva ? totale : 0;
    const aliquota_iva = parseFloat(el?.aliquota_iva?.split('-')[1]) / 100;
    const iva =
      el.imponibile == 1 && aliquota_iva > 0
        ? totale_imponibile * aliquota_iva
        : 0;
    const totale_ivato = totale_imponibile + iva;
    return {
      order,
      descrizione,
      tariffa,
      imponibile,
      spese_gen,
      spese_gen_perc,
      sconto,
      totale_imponibile,
      totale_non_imponibile,
      iva,
      aliquota_iva,
      totale_ivato,
    };
  });
  const ritenuta_acco = ritenuta ? parseFloat(ritenuta.split('-')[1]) / 100 : 0;
  const tot_onorari = listini_spese?.reduce((acc, oggetto) => {
    if (oggetto.imponibile === 1 || oggetto.imponibile === '1') {
      return acc + oggetto.tariffa;
    }
    return acc;
  }, 0);
  const spese_gen = listini_spese?.reduce(
    (acc, oggetto) => acc + oggetto.spese_gen,
    0
  );
  const sconto = listini_spese?.reduce(
    (acc, oggetto) => acc + oggetto.sconto,
    0
  );
  const totOnorari = tot_onorari + spese_gen - sconto;

  // eslint-disable-next-line no-underscore-dangle
  const imponibile_ = listini_spese?.reduce(
    (acc, oggetto) => acc + oggetto.totale_imponibile,
    0
  );

  const imponibile = imponibile_ * (cpap === 1 ? 1 + 0.04 : 1);

  const contributo_cassa = cpap === 1 ? totOnorari * 0.04 : 0;
  const ritenuta_acconto = ritenuta !== '' ? totOnorari * ritenuta_acco : 0;
  const imposta = imposta_bollo === 1 && totOnorari > 77.47 ? 2 : 0;

  const non_imponibile = listini_spese?.reduce(
    (acc, oggetto) => acc + oggetto.totale_non_imponibile,
    0
  );
  const iva_contributo_cassa = contributo_cassa * 0.22 || 0;
  const iva =
    listini_spese?.reduce((acc, oggetto) => acc + oggetto.iva, 0) +
    iva_contributo_cassa;

  return {
    totaleTariffe: tot_onorari,
    totaleOnorari: totOnorari,
    speseGenerali: spese_gen,
    sconto,
    contributoCassa: contributo_cassa,
    totaleImponibile: imponibile,
    totaleIVA: iva,
    totaleNonImponibile: non_imponibile,
    totaleDocumento: imponibile + iva + non_imponibile,
    ritenutaAcconto: ritenuta_acconto,
    impostaDiBollo: imposta,
    totalePreventivo:
      imponibile + iva - ritenuta_acconto + imposta + non_imponibile,
  };
}

export const base64toBlob = (base64Data, contentType): Blob => {
  contentType = contentType || '';
  const sliceSize = 1024;
  const byteCharacters = Buffer.from(base64Data, 'base64').toString('latin1'); //atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
};

export const blobToBase64 = (
  blob: Blob,
  mimeType: string,
  noPrefix = false
) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const dataUrlPrefix = `data:${mimeType};base64,`;
      const base64WithDataUrlPrefix = reader.result as string;
      const base64 = noPrefix
        ? base64WithDataUrlPrefix.replace(dataUrlPrefix, '')
        : base64WithDataUrlPrefix;
      resolve(base64);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const blobToFile = (blob: Blob, fileName: string): File => {
  return new File([blob], fileName, {
    type: blob.type,
  });
};
