import { ofType } from 'redux-observable';
import {
  catchError,
  concatMap,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { concat, defer, from, of } from 'rxjs';
import {
  httpMethods,
  singleFileUpload,
} from '../../../../../../services/http-service';
import {
  SEND_CHAT_ATTACHMENT_MESSAGE_TEAMS,
  SEND_CHAT_MESSAGE_TEAMS,
} from '../../../data.const';
import {
  setLoaders,
  setPopupInfo,
  storeTeamsUploadedAttachmentAction,
} from '../../../data.action';

// https://learn.microsoft.com/en-us/graph/api/chatmessage-post?view=graph-rest-1.0&tabs=http#example-4-send-a-message-with-file-attachment-in-it

const sendChatAttachmentMessageTeamsEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(SEND_CHAT_ATTACHMENT_MESSAGE_TEAMS),
    map(({ payload }: any) => payload),
    tap((p) => console.log(`[epic ${SEND_CHAT_ATTACHMENT_MESSAGE_TEAMS}]`, p)),
    withLatestFrom(state$),
    switchMap(([payload, _state]) => {
      const { items, chatId } = payload;
      return concat(
        of(setLoaders({ sendMessageLoader: true })),
        defer(() =>
          concat(
            from(items).pipe(
              concatMap((item: any) =>
                from(
                  singleFileUpload({
                    path: `microsoft/onedrive/teams/attachment`,
                    method: httpMethods.POST,
                    body: item,
                  })
                ).pipe(
                  map((p: any) => {
                    if (p?.status > 299) {
                      throw new Error(p.message ?? p.errorBody);
                    }
                    return p?.data?.result;
                  }),
                  switchMap((p) =>
                    concat(
                      of(storeTeamsUploadedAttachmentAction(p)),
                      of({
                        type: SEND_CHAT_MESSAGE_TEAMS,
                        payload: {
                          chatId,
                          body: {
                            body: {
                              contentType: 'html',
                              content: `<attachment id="${p.id}"></attachment>`,
                            },
                            attachments: [
                              {
                                id: p.id, // id or eTag
                                contentType: 'reference',
                                contentUrl: p.webDavUrl || p.webUrl, // webDavUrl
                                name: p.name, // name
                              },
                            ],
                          },
                        },
                      })
                    )
                  ),
                  catchError((err) => {
                    console.error(
                      `[epic ${SEND_CHAT_ATTACHMENT_MESSAGE_TEAMS}] error`,
                      err
                    );
                    return of(
                      setPopupInfo({
                        opened: true,
                        message: err.message ?? 'Operazione non riuscita',
                        type: 'BAD',
                        timer: 3000,
                      })
                    );
                  })
                )
              )
            ),
            of(
              setPopupInfo({
                opened: true,
                message:
                  items.length > 1
                    ? 'Documenti caricati'
                    : 'Documento caricato',
                type: 'GOOD',
                timer: 3000,
              })
            )
          )
        ),
        of(setLoaders({ sendMessageLoader: false }))
      );
    })
  );

export default sendChatAttachmentMessageTeamsEpic;
