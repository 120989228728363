import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { concat, defer, from, of } from 'rxjs';
import { httpMethods, request } from '../../../../../../services/http-service';
import { DELETE_DOCUMENTO_ONEDRIVE } from '../../../data.const';
import { setLoaders, setPopupInfo } from '../../../data.action';

const deleteDocumentoOnedriveEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(DELETE_DOCUMENTO_ONEDRIVE),
    map(({ payload }: any) => {
      const { createdAt, updatedAt, ...rest } = payload;
      if (rest.id === 'crea') {
        delete rest.id;
      }
      return rest;
    }),
    tap((p) => console.log(`[epic ${DELETE_DOCUMENTO_ONEDRIVE}]`, p)),
    withLatestFrom(state$),
    switchMap(([body, state]) => {
      if (!state.data.microsoftAccountStatus?.microsoftConfigured) {
        return of();
      }

      return concat(
        of(setLoaders({ documentoLoader: true, page: true })),
        defer(() =>
          from(
            request({
              path: `microsoft/onedrive/document`,
              method: httpMethods.DELETE,
              body,
            })
          ).pipe(
            switchMap((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message ?? p.errorBody);
              }
              return of(
                setPopupInfo({
                  opened: true,
                  message:
                    body?.ids?.length > 1
                      ? 'Documenti eliminati'
                      : 'Documento eliminato',
                  type: 'GOOD',
                  timer: 3000,
                })
              );
            }),
            catchError((err) => {
              console.error(`[epic ${DELETE_DOCUMENTO_ONEDRIVE}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: err.message ?? 'Si é verificato un errore',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ documentoLoader: false, page: false }))
      );
    })
  );

export default deleteDocumentoOnedriveEpic;
