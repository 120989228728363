import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of, concat, defer, debounceTime } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { RETRIEVE_CHATS } from '../../data.const';
import { setLoaders, setPopupInfo, storeChats } from '../../data.action';

const retrieveChatsEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_CHATS),
    debounceTime(20),
    map(({ payload }: any) => payload),
    // tap(p => console.log(`[epic ${RETRIEVE_CHATS}]`, p)),
    withLatestFrom(state$),
    switchMap(([_state]) => {
      return concat(
        of(setLoaders({ chatsLoader: true })),
        defer(() =>
          from(
            request({
              path: `chats`,
              method: httpMethods.GET,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return p?.result;
            }),
            tap((p) =>
              console.log(`[epic ${RETRIEVE_CHATS}] Retrieved chats:`, p)
            ),
            switchMap((data) => of(storeChats(data))),
            catchError((err) => {
              console.error(`[epic ${RETRIEVE_CHATS}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: 'Si é verificato un errore',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ chatsLoader: false }))
      );
    })
  );

export default retrieveChatsEpic;
