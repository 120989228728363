import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of, concat, defer, EMPTY } from 'rxjs';
import { httpMethods, request } from '../../../../../../services/http-service';
import { setLoaders, setPopupInfo } from '../../../data.action';
import { SEND_CHAT_MESSAGE_TEAMS } from '../../../data.const';

const sendChatMessageTeamsEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(SEND_CHAT_MESSAGE_TEAMS),
    map(({ payload }: any) => {
      const { chatId, body } = payload;
      return { body, id: chatId };
    }),
    // tap(p => console.log(`[epic ${SEND_CHAT_MESSAGE_TEAMS}]`, p)),
    withLatestFrom(state$),
    switchMap(([{ body, id }, _state]) => {
      return concat(
        of(setLoaders({ sendMessageLoader: true })),
        defer(() =>
          from(
            request({
              path: `microsoft/chats/${id}/messages`,
              method: httpMethods.POST,
              body,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message ?? p.errorBody);
              }
              return p?.result;
            }),
            tap((p) =>
              console.log(`[epic ${SEND_CHAT_MESSAGE_TEAMS}] Sent message:`, p)
            ),
            switchMap(() => EMPTY),
            /* map((_p) => {
              return {
                type: RETRIEVE_CHAT_MESSAGES_TEAMS,
                payload: id,
              };
            }), */
            catchError((err) => {
              console.error(`[epic ${SEND_CHAT_MESSAGE_TEAMS}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: err.message ?? 'Si é verificato un errore',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ sendMessageLoader: false }))
      );
    })
  );

export default sendChatMessageTeamsEpic;
