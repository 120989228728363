import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of, concat, defer, debounceTime } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { RETRIEVE_CHAT_MESSAGES } from '../../data.const';
import {
  setLoaders,
  setPopupInfo,
  storeCurrentChatMessagesAction,
} from '../../data.action';

const retrieveChatMessagesEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_CHAT_MESSAGES),
    debounceTime(20),
    map(({ payload }: any) => payload),
    // tap(p => console.log(`[epic ${RETRIEVE_CHAT_MESSAGES}]`, p)),
    withLatestFrom(state$),
    switchMap(([id, _state]) => {
      return concat(
        of(setLoaders({ messagesLoader: true })),
        defer(() =>
          from(
            request({
              path: `chats/${id}/messages`,
              method: httpMethods.GET,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return p?.result;
            }),
            tap((p) =>
              console.log(
                `[epic ${RETRIEVE_CHAT_MESSAGES}] Retrieved messages:`,
                p
              )
            ),
            switchMap((data) => of(storeCurrentChatMessagesAction(data))),
            catchError((err) => {
              console.error(`[epic ${RETRIEVE_CHAT_MESSAGES}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: 'Si é verificato un errore',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ messagesLoader: false }))
      );
    })
  );

export default retrieveChatMessagesEpic;
